import React, { ReactNode } from 'react'
import cssModules from 'react-css-modules'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
// import FAQ from 'components/FAQ/FAQ'
import { ModalConductorProvider } from 'components/modal'
import Copy from 'shared/components/ui/Copy/Copy'
import Button from 'components/controls/Button/Button'
import Address from 'components/ui/Address/Address'
import { AddressFormat } from 'domain/address'
import bannerLinkImg from 'images/bannerLink.png'
import tokenImg from 'images/tokenImg.png'
import styles from './styles.scss'

type ComponentProps = {
  page: 'history' | 'invoices'
  children?: ReactNode
  BalanceForm: ReactNode
}

function DashboardLayout(props: ComponentProps) {
  const { children, page } = props

  const balanceRef = React.useRef(null) // Create a ref object

  let activeView = 0

  if (page === 'history' && !isMobile) {
    activeView = 1
  }
  if (page === 'invoices') activeView = 2

  return (
    <article className="data-tut-start-widget-tour">
      {window.CUSTOM_LOGO && <img className="cutomLogo" src={window.CUSTOM_LOGO} alt="logo" />}
      <section
        // eslint-disable-next-line react/no-unknown-property
        styleName={`wallet ${window.CUSTOM_LOGO ? 'hasCusomLogo' : ''}`}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <div className="data-tut-store" styleName="walletContent" ref={balanceRef}>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div styleName="walletBalance">
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {props.BalanceForm}

            {/* <div
              className={cx({
                [styles.desktopEnabledViewForFaq]: true,
                [styles.faqWrapper]: true,
              })}
            >
              <FAQ />
            </div> */}
          </div>
          {/*          <div className={styles.copyAddress}>
            <div className={styles.copyAddressRight}>
              <div className={styles.copyAddressRightTitle}>
                <FormattedMessage id="poolAddress" defaultMessage="Pool address:" />
              </div>
              <div className={styles.copyAddressRightAddress}>
                {
                  isMobile
                    ? (
                      <Address
                        address=""
                        format={AddressFormat.Short}
                      />
                    ) : '0xbf664aa850257B7F27a7f207540206A4Cdd96f36'
                }
              </div>
            </div>
            <div className={styles.copyAddressLeft}>
              <Copy text="">
                <Button blue className={styles.copyAddressBtn}>
                  <FormattedMessage id="CopyAddressBtn" defaultMessage="Copy" />
                </Button>
              </Copy>
            </div>
          </div> */}
          {/* eslint-disable-next-line max-len */}
          {/*          <a href="" target="_blank" className={styles.bannerLink} rel="noreferrer">
            <img src={bannerLinkImg} alt="banner link" />
            <span>
              <FormattedMessage id="ParticipationForm" defaultMessage="Fill out the participation form" />
            </span>
          </a>
           eslint-disable-next-line max-len
          <a href="" target="_blank" className={`${styles.bannerLink} ${styles.bannerLinkSecond}`} rel="noreferrer">
            <img src={tokenImg} alt="token buy link" />
            <span>
              <FormattedMessage id="ApplicationParticipationForm" defaultMessage="Application for investment in the AMA" />
            </span>
          </a> */}
          <div
            // eslint-disable-next-line react/no-unknown-property
            styleName={cx({
              yourAssetsWrapper: activeView === 0,
              activity: activeView === 1 || activeView === 2,
              active: true,
            })}
          >
            <ModalConductorProvider>{children}</ModalConductorProvider>
          </div>
          {/* <div
            className={cx({
              [styles.mobileEnabledViewForFaq]: true,
              [styles.faqWrapper]: true,
            })}
          >
            <FAQ />
          </div> */}
        </div>
      </section>
    </article>
  )
}

export default cssModules(DashboardLayout, styles, { allowMultiple: true })
