import React from 'react'
import { FormattedMessage } from 'react-intl'
import logoAmanat from '../../../images/logo/logo.png'
import styles from './index.scss'

function Trending({ trending, btcToUsd }) {

  const trendingElement = trending.slice(0, 5).map(({ item }, index) => (
    <div key={index} className={styles.trendingItemWrapper}>
      <div className={styles.trendingItem}>
        <div className={styles.trendingItemLeft}>
          <img className={styles.trendingItemLeftImg} src={item.small} alt={item.name} />
          <div className={styles.trendingItemLeftContent}>
            <p className={styles.trendingItemLeftName}>{item.name}</p>
            <p>{item.symbol}</p>
          </div>
        </div>
        <div className={styles.trendingItemRight}>
          $
          {(item.price_btc * btcToUsd).toFixed(7)}
        </div>
      </div>
    </div>
  ),
  )
  return (
    <div className={styles.trending}>
      <h2 className={styles.trendingTitle}>
        <FormattedMessage
          id="trendingTitle"
          defaultMessage="Trending Coins"
        />
      </h2>
      <div className={styles.trendingGrid}>
        <div className={styles.trendingItemWrapper}>
          <div className={styles.trendingItem}>
            <div className={styles.trendingItemLeft}>
              <img className={styles.trendingItemLeftImg} src={logoAmanat} alt="Amanat Coin" width={50} height={50} />
              <div className={styles.trendingItemLeftContent}>
                <p className={styles.trendingItemLeftName}>Amanat Coin</p>
                <p>AMA</p>
              </div>
            </div>
            <div className={styles.trendingItemRight}>$0.013</div>
          </div>
        </div>
        {trendingElement}
      </div>
    </div>
  )
}

export default Trending
