import { useState } from 'react'
import AccordionHeader from '../AccordionHeader/AccordionHeader'
import AccordionContent from '../AccordionContent/AccordionContent'
import styles from './Accordion.scss'

/* eslint linebreak-style: ["error", "windows"] */

export default function Accordion({ title, content }, index: number) {

  const [isActive, setIsActive] = useState(false)

  return (
    <div key={index} className={styles.accordion}>
      <AccordionHeader
        title={title}
        onClick={() => setIsActive(!isActive)}
        isActive={isActive}
      />
      {isActive && <AccordionContent content={content} />}
    </div>
  )
}
