import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CoinSearch from './CoinsAndExchanges'
import Trending from './Trending/index'

const tengeUrl = 'https://api.apilayer.com/exchangerates_data/convert?to=KZT&from=USD&amount=1'
const baseUrl = 'https://api.coingecko.com/api/v3/'
const coinsUrl = 'coins/markets?vs_currency=usd&order=market_cap_desc&sparkline=true&per_page=20&page='
const exchangesUrl = 'exchanges?vs_currencies=usd&per_page=20&page='
const apiKey = 'zdhc11uGcAunbtZLTK1nLPJ4zlkY4AwW'
const searchUrl = 'search/trending'
function MarketAnalytics() {
  const [trending, setTrending] = useState<string[]>([])
  const [coins, setCoins] = useState([])
  const [page, setPage] = useState(1)
  const [btcToUsd, setBtcToUsd] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usdToKzt, setUsdToKzt] = useState(0)
  const [exchanges, setExchanges] = useState([])
  const urlExchange = `${baseUrl}${exchangesUrl}${page}`
  const url = `${baseUrl}${coinsUrl}${page}`

  useEffect(() => {
    axios.get(`${url}`).then(res => {
      setCoins(res.data)
      res.data.forEach(coin => {
        if (coin.id === 'bitcoin') {
          setBtcToUsd(coin.current_price)
        }
      })
    })

  }, [url])

  useEffect(() => {
    axios.get(tengeUrl, { headers: { apiKey } }).then(res => {
      setUsdToKzt(res.data.result)
    })
    axios.get(`${baseUrl}${searchUrl}`).then(res => {
      setTrending(res.data.coins)
    })
  }, [])

  useEffect(() => {
    axios.get(`${urlExchange}`).then(res => {
      setExchanges(res.data)
    })
  }, [url])

  return (
    <div>
      <Trending trending={trending} btcToUsd={btcToUsd}  />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CoinSearch coins={coins} setCoins={setCoins} btcToUsd={btcToUsd} page={page} setPage={setPage} exchanges={exchanges} />
    </div>
  )
}

export default MarketAnalytics
