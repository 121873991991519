import CSSModules from 'react-css-modules'
import config from 'helpers/externalConfig'
import { FormattedMessage } from 'react-intl'
import styles from '../Footer.scss'

function ServiceLinks() {
  const serviceLink = config?.opts?.ui?.serviceLink || 'https://onout.org/wallet'

  return (
    <div styleName="serviceLinks">
      <span>
        <FormattedMessage id="poweredBy" defaultMessage="Powered by" />
        {' '}
        <a href={serviceLink} target="_blank" rel="noreferrer">Amadao Network</a>
      </span>
    </div>
  )
}

export default CSSModules(ServiceLinks, styles, { allowMultiple: true })
