import React from 'react'
import styles from './AccordionContent.scss'

/* eslint linebreak-style: ["error", "windows"] */
export default function AccordionContent({ content }) {
  return (
    <div className={styles.accordionContent}>
      {content}
    </div>
  )
}