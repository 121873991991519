import React from 'react'
import { Link } from 'react-router-dom'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import styles from './index.scss'

function CoinItem({ coin }) {
  // eslint-disable-next-line camelcase
  const { id, market_cap_rank, name, image, symbol, current_price, price_change_percentage_24h, total_volume, market_cap, sparkline_in_7d } = coin

  return (
    <tr className={styles.tableTr}>
      {/* eslint-disable-next-line camelcase */}
      <td className="text-left">{market_cap_rank + 1}</td>
      <td className="text-left">
        <Link to={`/analytics/coin/${id}`}>
          <div className={styles.tableTdImageWrapper}>
            <img className={styles.tableTdImage} src={image} alt={name} />
            <p className={styles.tableTdText}>{name}</p>
          </div>
        </Link>
      </td>
      <td className="uppercase text-left">{symbol}</td>
      <td className="text-left">
        $
        {/* eslint-disable-next-line camelcase */}
        {current_price.toLocaleString()}
      </td>
      <td className="text-left">
        {/* eslint-disable-next-line camelcase */}
        {price_change_percentage_24h > 0
        // eslint-disable-next-line camelcase
          ? (<p className={`${styles.tableTdText} ${styles.tableTdTextGreen}`}>{name === 'Amanat Coin' ? price_change_percentage_24h : price_change_percentage_24h.toFixed(2)}</p>)
        // eslint-disable-next-line camelcase
          : (<p className={`${styles.tableTdText} ${styles.tableTdTextRed}`}>{price_change_percentage_24h.toFixed(2)}</p>)}
      </td>
      <td className="w-[180px] hidden md:table-cell text-left">
        {
          name !== 'Amanat Coin' && '$'
        }
        {/* eslint-disable-next-line camelcase */}
        {total_volume.toLocaleString()}
      </td>
      <td className="w-[180px] hidden sm:table-cell text-left">
        $
        {/* eslint-disable-next-line camelcase */}
        {market_cap.toLocaleString()}
      </td>
      <td className="text-right">
        {/* eslint-disable-next-line camelcase */}
        <Sparklines data={sparkline_in_7d.price}>
          <SparklinesLine color="teal" />
        </Sparklines>
      </td>
    </tr>
  )
}

export default CoinItem
