import cx from 'classnames'
import { isMobile } from 'react-device-detect'

import config from 'helpers/externalConfig'

import CSSModules from 'react-css-modules'
import version from 'helpers/version'
import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import styles from './Footer.scss'

import SwitchLang from './SwitchLang/SwitchLang'
import ServiceLinks from './ServiceLinks'

function Footer() {
  const showServiceLinks = !config.opts.ui.hideServiceLinks

  return (
    <footer
      className={cx({
        [styles.footer]: true,
        [styles.mobile]: isMobile,
      })}
      data-version-name={version.name}
      data-version-url={version.link}
    >
      { /* @ts-ignore */ }
      <WidthContainer styleName="container">
        <SwitchLang />
        {showServiceLinks && (
          <ServiceLinks />
        )}
      </WidthContainer>
    </footer>
  )
}

export default CSSModules(Footer, styles, { allowMultiple: true })
