import React, { useState, useContext, useEffect } from 'react'
import { FormattedMessage, IntlContext } from 'react-intl'
import CoinItem from './CoinItem'
import ExchangeItem from '../ExchangeItem'
import styles from './index.scss'

function CoinsAndExchanges({ coins, page, exchanges, btcToUsd, setPage }) {
  const { locale } = useContext(IntlContext)
  const [search, setSearch] = useState('')
  const [currentTab, setCurrentTab] = useState('cryptocurrencies')
  const [activeCurrentTab, setActiveCurrentTab] = useState(0)

  const [dataCoins, setDataCoins] = useState([])
  const [dataExchanges, setDataExchanges] = useState([])

  const staticCoin = {
    id: 'ama',
    market_cap_rank: 0,
    name: 'Amanat Coin',
    image: 'https://dex.amadao.network/images/logo_574b97.png',
    symbol: 'ama',
    current_price: 0.013,
    price_change_percentage_24h: 0.00001,
    total_volume: '~',
    market_cap: 13000000,
    sparkline_in_7d: {
      price: [
        1.000187649842106,
        0.9997025396537346,
        0.9989370821347379,
        0.9993860369056845,
        0.9995012343222066,
        0.9998611214843521,
        0.9980287637528534,
        0.9992239113173722,
        0.9998701409308156,
        1.000935628057725,
        0.9997539747745761,
        0.9995995390922032,
        0.999091696221606,
        0.9999965395947608,
        1.0003366487320808,
        0.9994353978807636,
        0.9998260326826287,
        0.9998017505494324,
        0.9993893991797808,
        0.9996205182884615,
        1.0001828293718098,
        1.000439473706304,
        0.9993339896252922,
        0.9999700906247606,
        1.0000809567488775,
        0.9995635000504557,
        0.9998807497383958,
        0.9997162207433349,
        1.000039376442485,
        1.000147840172435,
        0.999659397859921,
        1.000801144118561,
        0.996555649049582,
        1.001181614531508,
        0.9997704124443487,
        0.9995831822148544,
        1.0000599279835114,
        0.9998967339108588,
        0.9999990155767435,
        1.0001694429802899,
        0.9994605855596702,
        0.9999770810266122,
        0.9996976672827299,
        1.000112111995642,
        1.0005744817204814,
        1.0001326669345703,
        1.0000805208179393,
        1.0000415891744803,
        0.9990339875819483,
        1.0003806730333833,
        0.9999968713108851,
        0.9998729996874597,
        1.000056117201571,
        1.0002883772021014,
        0.9996173631805553,
        1.0003868847105362,
        1.0001363173212094,
        0.9996880085355184,
        0.9990801614158907,
        1.0008100033794662,
        1.0003471750512831,
        1.0001010415271674,
        0.9999011021191262,
        1.0003120212214702,
        0.9997570717830286,
        0.9994583892536902,
        0.9977713025622251,
        0.9995197392242721,
        0.9999389195084899,
        0.9991838649180321,
        0.9993953948030765,
        0.9998573971849497,
        0.9996612880516148,
        1.0000916817306729,
        0.9998591833593399,
        0.9998425585689763,
        1.000640523646376,
        0.9995439403187287,
        1.0002685722594875,
        0.9993280589208582,
        0.9995376645507554,
        1.0003689305155887,
        0.9995892514800583,
        1.000489688324119,
        1.0000888798478516,
        0.9989691994246415,
        0.9999395421468446,
        1.0029301059270372,
        0.9996606438880886,
        1.0000285298298257,
        0.998780268899255,
        1.0004507981137685,
        0.9999101039569316,
        0.9987551723890883,
        0.9990711115821774,
        0.9990797665053776,
        0.9996611688955361,
        0.999573999472256,
        0.9993345259626308,
        0.9998359063955111,
        0.9963942109365351,
        0.9998873493489195,
        0.9993549668523548,
        0.9993963646881083,
        0.9999890661843477,
        0.9982682477229023,
        1.0007489172869055,
        0.9994416274131419,
        1.0005305285228552,
        0.9998302222704938,
        0.9991876720228371,
        0.9999460933565721,
        1.0002969378018998,
        0.9993665305735363,
        0.9986429751440978,
        0.9981993895576412,
        0.9995689898298673,
        0.9996960347767062,
        0.9997519832027691,
        1.0006616228451943,
        0.9997126535113483,
        1.0004655981720774,
        0.9989086929817855,
        0.9998773366182395,
        0.9999467053879738,
        1.000134570864544,
        1.0002765906084077,
        0.997533997490411,
        0.9986098963930793,
        0.9991706868197504,
        0.9997619799845708,
        1.0008050344325468,
        0.999184101846933,
        0.999319432909939,
        1.0002662610857693,
        0.9979812143679258,
        1.0000043697163874,
        0.9995578671726448,
        1.0004522953913484,
        0.9998478467088371,
        0.9999493845912439,
        0.9993954879893419,
        0.9933103722972656,
        0.9974490728754948,
        0.9994326084928592,
        1.000827276423435,
        1.0001109110654964,
        0.9997351665819785,
        0.9993574292108022,
        0.9999400386674369,
        0.9995074675417875,
        1.0010682287885408,
        0.9997133484305786,
        0.9988435916443172,
        0.9990225769813548,
        1.0013228819942888,
        0.999459940254537,
        0.9992993053083288,
        0.9995409628153556,
        0.9986149194023403,
        0.9995384348126731,
        1.0009807979897205,
        0.9991752470757633,
        0.9990760087885757,
        0.9998960834574538,
        0.9999554879407347,
        0.9990180875615942,
        1.000211621476853,
      ],
    },
  }

  const staticExchange = {
    id: 'dexamadao',
    trust_score_rank: 0,
    name: 'Amadao Dex',
    image: 'https://dex.amadao.network/images/logo_574b97.png',
    trust_score: 10,
    url: 'https://dex.amadao.network/',
    country: 'Kazakhstan',
    has_trading_incentive: false,
    trade_volume_24h_btc: '~',
    trade_volume_24h_btc_normalized: '~',
    year_established: '2023',
  }

  useEffect(() => {
    const newCoins = [staticCoin, ...coins]
    const newExchange = page === 1 ? [staticExchange, ...exchanges] : exchanges
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setDataCoins(newCoins)
    setDataExchanges(newExchange)
  }, [coins, exchanges, page])

  function prev(event) {
    event.preventDefault()
    setPage(Math.max(page - 1, 1))
  }

  function next(event) {
    event.preventDefault()
    setPage(page + 1)
  }

  const exchangeElement = dataExchanges.filter((value) => {
    if (search === '') {
      return value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } if (value.name.toLowerCase().includes(search.toLowerCase())) {
      return value
    }
    return false
  }).map((exchange) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ExchangeItem exchange={exchange} btcToUsd={btcToUsd} key={exchange.id} />
  ))

  const coinElement = dataCoins.filter((value) => {
    if (search === '') {
      return value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    } // @ts-ignore
    if (value.name.toLowerCase().includes(search.toLowerCase())) {
      return value
    }
    return false
  }).map((coin) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CoinItem coin={coin} key={coin.id} />
  ))
  const handleCurrentTab = (e, type, active) => {
    e.preventDefault()
    setActiveCurrentTab(active)
    setCurrentTab(type)
  }

  return (
    <div className={styles.coins}>
      <div className={styles.coinsHeader}>
        <h2 className={styles.coinsTitle}>
          <FormattedMessage
            id="coinsTitle"
            defaultMessage="Search Crypto"
          />
        </h2>
        <div className={styles.coinsHeaderOptions}>
          <ul className={styles.optionsFilter}>
            <li className={styles.optionsFilterItem}>
              {/* eslint-disable-next-line react/button-has-type,max-len */}
              <button id="cryptocurrencies" onClick={e => { handleCurrentTab(e, 'cryptocurrencies', 0) }} className={activeCurrentTab === 0 ? `${styles.optionsFilterButton} ${styles.optionsFilterButtonActive}` : `${styles.optionsFilterButton}`}>
                <FormattedMessage
                  id="cryptocurrenciesButton"
                  defaultMessage="Cryptocurrencies"
                />
              </button>
            </li>
            <li className={styles.optionsFilterItem}>
              {/* eslint-disable-next-line react/button-has-type,max-len */}
              <button id="exchanges" onClick={e => { handleCurrentTab(e, 'exchanges', 1) }} className={activeCurrentTab === 1 ? `${styles.optionsFilterButton} ${styles.optionsFilterButtonActive}` : `${styles.optionsFilterButton}`}>
                <FormattedMessage
                  id="exchangesButton"
                  defaultMessage="Exchanges"
                />
              </button>
            </li>
          </ul>

          <form className={styles.optionsSearchForm}>
            {/* eslint-disable-next-line max-len */}
            <input onChange={(e) => setSearch(e.target.value)} className={styles.optionsSearch} type="text" placeholder={locale === 'en' ? 'Search a Coin' : 'Поиск монеты'} />
          </form>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableWrapperScroll}>
          <table className={styles.table}>
            <thead>
              <tr className={`${styles.tableTr} ${styles.tableTheadTr}`}>
                {
                  currentTab === 'cryptocurrencies' ? (
                    <>
                      <th className="px-2 text-left">#</th>
                      <th className="text-left">
                        <FormattedMessage
                          id="tableThCoin"
                          defaultMessage="Coin"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThSign"
                          defaultMessage="Sign"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThPrice"
                          defaultMessage="Price"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableTh24h"
                          defaultMessage="24h"
                        />
                      </th>
                      <th className="hidden md:table-cell">
                        <FormattedMessage
                          id="tableTh24hVolume"
                          defaultMessage="24h Volume"
                        />
                      </th>
                      <th className="hidden sm:table-cell">
                        <FormattedMessage
                          id="tableThMkt"
                          defaultMessage="Mkt Cap"
                        />
                      </th>
                      <th className="text-right">
                        <FormattedMessage
                          id="tableThLast7Days"
                          defaultMessage="Last 7 Days"
                        />
                      </th>
                    </>
                  ) : (
                    <>
                      <th className="px-2 text-left">#</th>
                      <th className="text-left">
                        <FormattedMessage
                          id="tableThExchange"
                          defaultMessage="Exchange"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThTrustScor"
                          defaultMessage="Trust Scor"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThYear"
                          defaultMessage="Year"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThCountry"
                          defaultMessage="Country"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="tableThTradingIncentive"
                          defaultMessage="Trading Incentive"
                        />
                      </th>
                      <th className="hidden md:table-cell">
                        <FormattedMessage
                          id="tableThTradeVolume24hNormalize"
                          defaultMessage="Trade volume 24h normalize"
                        />
                      </th>
                      <th className="hidden sm:table-cell text-right">
                        <FormattedMessage
                          id="tableThTradeVolume24h"
                          defaultMessage="Trade volume 24h"
                        />
                      </th>
                    </>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {currentTab === 'cryptocurrencies' ? coinElement : exchangeElement}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.optionsPagination}>
        {/* eslint-disable-next-line react/button-has-type */}
        <button onClick={prev}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line max-len */}
            <path d="M14.9998 19.92L8.47984 13.4C7.70984 12.63 7.70984 11.37 8.47984 10.6L14.9998 4.07999" stroke="var(--color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <span>{page}</span>
        {/* eslint-disable-next-line react/button-has-type */}
        <button onClick={next}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line max-len */}
            <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07999" stroke="var(--color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default CoinsAndExchanges
