import React from 'react'
import styles from '../CoinsAndExchanges/index.scss'

function ExchangeItem({ exchange, btcToUsd }) {
  // eslint-disable-next-line camelcase
  const { trust_score_rank, name, image, trust_score, url, country, has_trading_incentive, trade_volume_24h_btc, trade_volume_24h_btc_normalized, year_established } = exchange

  return (
    <tr className={styles.tableTr}>
      {/* eslint-disable-next-line camelcase */}
      <td className="text-left">{trust_score_rank + 1}</td>
      <td className="text-left">
        <a href={url} target="_blank" rel="noreferrer">
          <div className={styles.tableTdImageWrapper}>
            <img className={styles.tableTdImage} src={image} alt={name} />
            <p className={styles.tableTdText}>{name}</p>
          </div>
        </a>
      </td>
      {/* eslint-disable-next-line camelcase */}
      <td className="text-left">{trust_score}</td>
      {/* eslint-disable-next-line camelcase */}
      <td className="text-left">{year_established}</td>
      <td className="text-left">{country}</td>
      {/* eslint-disable-next-line camelcase */}
      <td className="text-left">{has_trading_incentive ? 'Yes' : 'No'}</td>
      <td className="text-left">
        {
          name !== 'Amadao Dex' && '$'
        }
        {/* eslint-disable-next-line camelcase */}
        {name === 'Amadao Dex' ? trade_volume_24h_btc_normalized : (trade_volume_24h_btc_normalized * btcToUsd).toFixed(3)}
      </td>
      <td className="text-right">
        {
          name !== 'Amadao Dex' && '$'
        }
        {/* eslint-disable-next-line camelcase */}
        {name === 'Amadao Dex' ? trade_volume_24h_btc : (trade_volume_24h_btc * btcToUsd).toFixed(3)}
      </td>
    </tr>
  )
}

export default ExchangeItem
