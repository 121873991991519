import React, { useEffect, useState } from 'react'
import Accordion from './Accordion/Accordion'
import categories from './data'
import styles from './HelpCenter.scss'
/* eslint linebreak-style: ["error", "windows"] */

function HelpCenter() {

  const [categoriesData, setCategoriesData] = useState(categories)
  const [category, setCategory] = React.useState('')

  const handleChangeCategory = (value: any) => {
    setCategory(value.target.value)
  }

  const applyFilters = () => {
    let updatedList = categories
    if (category) {
      updatedList = updatedList.filter((item) => item.type === category)
    }
    setCategoriesData(updatedList)
  }

  useEffect(() => {
    applyFilters()
    // eslint-disable-next-line
  }, [category]);

  return (
    <article className="data-tut-start-widget-tour">
      <section>
        <div className={styles.helpCenterWrapper}>
          <div className={styles.helpCenterHeader}>
            <h1 className={styles.helpCenterHeaderTitle}>Часто задаваемые вопросы</h1>
            <h5>
              Прежде чем подавать заявку, пожалуйста, ознакомьтесь c популярными вопросами и ответами. Возможно, вы найдете ответ на свой вопрос
            </h5>
          </div>
          <div className={styles.helpCenterList}>
            <div className={styles.helpCenterListTab} onChange={handleChangeCategory}>
              <label
                htmlFor="cat-all"
                className={category === "" ? `${styles.helpCenterListTabItem} ${styles.helpCenterListTabItemActive}` : styles.helpCenterListTabItem}
              >
                Все
              </label>
              <input
                id="cat-all"
                className={styles.helpCenterListTabInput}
                type="radio"
                value=""
                name="all"
                checked={category === ''}
                readOnly
              />
              <input
                id="cat-reg"
                className={styles.helpCenterListTabInput}
                type="radio"
                value="registration"
                name="registration"
                checked={category === 'registration'}
                readOnly
              />
              <label
                htmlFor="cat-reg"
                className={category === "registration" ? `${styles.helpCenterListTabItem} ${styles.helpCenterListTabItemActive}` : styles.helpCenterListTabItem}
              >
                Регистрация
              </label>
              <input
                id="cat-ver"
                className={styles.helpCenterListTabInput}
                type="radio"
                value="verification"
                name="verification"
                checked={category === 'verification'}
                readOnly
              />
              <label
                htmlFor="cat-ver"
                className={category === "verification" ? `${styles.helpCenterListTabItem} ${styles.helpCenterListTabItemActive}` : styles.helpCenterListTabItem}
              >
                Верификация
              </label>
            </div>
            {categoriesData.map(({ title, content }, i) => (
              <Accordion key={title + i} title={title} content={content} />
            ))}
          </div>
        </div>
      </section>
    </article>
  )
}

export default HelpCenter