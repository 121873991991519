import { useState, useEffect } from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'
import actions from 'redux/actions'
import cx from 'classnames'
import { BigNumber } from 'bignumber.js'

import styles from 'pages/Wallet/Wallet.scss'
import Button from 'components/controls/Button/Button'
import InlineLoader from 'components/loaders/InlineLoader/InlineLoader'
import config from 'helpers/externalConfig'
import metamask from 'helpers/metamask'

import dollar from './images/dollar.svg'
import btc from './images/btcIcon.svg'
// import amanat from './images/amanat.svg'

const BalanceForm = function ({
  activeFiat,
  activeCurrency,
  fiatBalance,
  currencyBalance,
  handleReceive,
  handleWithdraw,
  currency,
  currencyView = false,
  handleInvoice = () => {},
  isFetching = false,
  showButtons = true,
  type,
  singleWallet = false,
  multisigPendingCount = 10,
}) {

  const [selectedCurrency, setActiveCurrency] = useState(activeCurrency)
  const isWidgetBuild = config && config.isWidget

  useEffect(() => {
    if (type === 'wallet' && activeCurrency !== activeFiat.toLowerCase()) {
      setActiveCurrency('btc')
    } else {
      setActiveCurrency(activeCurrency)
    }
  }, [activeCurrency])

  const active = activeFiat ? activeFiat.toLowerCase() : 'usd'

  // @ToDo
  // в Data у валют есть флаги isUserProtected и isSMSProtected
  // нужно по ним проверять, а не по "служебному" названию монеты
  // Use flags in currency data (isUserProtected and isSMSProtected)
  // eslint-disable-next-line default-case
  switch (currency) {
    case 'btc (multisig)':
    case 'btc (pin-protected)':
      currency = 'BTC'
      break
  }

  const handleClickCurrency = (currency) => {
    setActiveCurrency(currency)
    actions.user.pullActiveCurrency(currency)
  }

  const handleGoToMultisig = () => {
    actions.multisigTx.goToLastWallet()
  }

  const buttonsDisabled = !((config.opts.ui.disableInternalWallet && metamask.isConnected()) || !config.opts.ui.disableInternalWallet)

  const sendButtonDisabled = !currencyBalance || buttonsDisabled

  const handleTargetBlankLink = () => {
    window.open('https://exchange.mercuryo.io/?currency=BNB&fiat_currency=USD', '_blank');
  }

  return (
    <div
      styleName={
        `${isWidgetBuild && !config.isFullBuild ? 'yourBalance widgetBuild' : 'yourBalance'}`
      }
    >
      <div styleName="yourBalanceTop" className="data-tut-widget-balance">
        <p styleName="yourBalanceDescr">
          {singleWallet
            ? <FormattedMessage id="YourWalletbalance" defaultMessage="Balance" />
            : <FormattedMessage id="Yourtotalbalance" defaultMessage="Ваш общий баланс" />}
        </p>
        <div styleName="yourBalanceValue">
          {selectedCurrency === active ? (
            // eslint-disable-next-line no-restricted-globals
            <p>
              {(activeFiat === 'USD' || activeFiat === 'CAD') && <img src={dollar} alt="dollar" />}
              {
                // eslint-disable-next-line no-restricted-globals
                !isNaN(fiatBalance) ? new BigNumber(fiatBalance).dp(2, BigNumber.ROUND_FLOOR).toString() : ''
              }
            </p>
          ) : (
            <p className="data-tut-all-balance">
              {currency.toUpperCase() === 'BTC' ? <img src={btc} alt="btc" /> : ''}
              {new BigNumber(currencyBalance).dp(6, BigNumber.ROUND_FLOOR).toString()}
            </p>
          )}
          {isFetching && (
            <div styleName="loaderHolder">
              <InlineLoader />
            </div>
          )}
        </div>
        <div styleName="yourBalanceCurrencies">
          {/* <button
            type="button"
            styleName={selectedCurrency === active ? 'active' : undefined}
            onClick={() => handleClickCurrency(active)}
          >
            ATC
          </button> */}
          {/* <span styleName="separator" /> */}
          <button
            type="button"
            styleName={selectedCurrency === active ? 'active' : undefined}
            onClick={() => handleClickCurrency(active)}
          >
            {active}
          </button>
          <span styleName="separator" />
          <button
            type="button"
            styleName={selectedCurrency === currency ? 'active' : undefined}
            onClick={() => handleClickCurrency(currency)}
          >
            {currencyView || currency}
          </button>
        </div>
      </div>
      {multisigPendingCount > 0 && (
        <div onClick={handleGoToMultisig}>
          <p styleName="multisigWaitCount">
            <FormattedMessage
              id="Balance_YouAreHaveNotSignegTx"
              defaultMessage="{count} transaction needs your confirmation"
              values={{
                count: multisigPendingCount,
              }}
            />
          </p>
        </div>
      )}
      <div
        className={cx({
          [styles.yourBalanceBottomWrapper]: true,
        })}
      >
        <div styleName="yourBalanceBottom">
          {showButtons ? (
            <div styleName="btns" className="data-tut-withdraw-buttons">
              <Button blue disabled={buttonsDisabled} id="depositBtn" onClick={() => handleReceive('Deposit')}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.25 8.5H7.75H13.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.25 16.5H8.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.75 16.5H14.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22.25 11.33V16.11C22.25 19.62 21.36 20.5 17.81 20.5H6.69C3.14 20.5 2.25 19.62 2.25 16.11V7.89001C2.25 4.38001 3.14 3.5 6.69 3.5H13.53" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.25 3.25H20.38C21.07 3.25 21.63 3.88 21.63 4.5C21.63 5.19 21.07 5.75 20.38 5.75H17.25V3.25Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.25 5.75H20.82C21.61 5.75 22.25 6.31 22.25 7C22.25 7.69 21.61 8.25 20.82 8.25H17.25V5.75Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.01 8.25V9.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.01 2V3.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.44 3.25H16.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.44 8.25H16.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <FormattedMessage id="YourtotalbalanceDeposit" defaultMessage="Пополнить" />
              </Button>
              <Button styleName="secondBtn" blue disabled={buttonsDisabled} id="depositBtn" onClick={handleTargetBlankLink}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.75 8.50496H22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.75 16.505H8.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M11.25 16.505H15.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.19 3.50496H18.3C21.86 3.50496 22.75 4.38496 22.75 7.89496V16.105C22.75 19.615 21.86 20.495 18.31 20.495H7.19C3.64 20.505 2.75 19.625 2.75 16.115V7.89496C2.75 4.38496 3.64 3.50496 7.19 3.50496Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <FormattedMessage id="buyCryptoBalance" defaultMessage="Купить" />
              </Button>
              <Button blue disabled={sendButtonDisabled} id={!sendButtonDisabled ? 'sendBtn' : ''} onClick={() => handleWithdraw('Send')}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.25 8.5H7.75H13.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.25 16.5H8.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.75 16.5H14.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22.25 11.33V16.11C22.25 19.62 21.36 20.5 17.81 20.5H6.69C3.14 20.5 2.25 19.62 2.25 16.11V7.89001C2.25 4.38001 3.14 3.5 6.69 3.5H13.53" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.25 3.25H20.38C21.07 3.25 21.63 3.88 21.63 4.5C21.63 5.19 21.07 5.75 20.38 5.75H17.25V3.25Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.25 5.75H20.82C21.61 5.75 22.25 6.31 22.25 7C22.25 7.69 21.61 8.25 20.82 8.25H17.25V5.75Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.01 8.25V9.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.01 2V3.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.44 3.25H16.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.44 8.25H16.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <FormattedMessage id="YourtotalbalanceSend" defaultMessage="Отправить" />
              </Button>
              <Button styleName="secondBtn" blue disabled={sendButtonDisabled} id={!sendButtonDisabled ? 'sendBtn' : ''} onClick={handleTargetBlankLink}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.75 8.50496H22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.75 16.505H8.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M11.25 16.505H15.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.19 3.50496H18.3C21.86 3.50496 22.75 4.38496 22.75 7.89496V16.105C22.75 19.615 21.86 20.495 18.31 20.495H7.19C3.64 20.505 2.75 19.625 2.75 16.115V7.89496C2.75 4.38496 3.64 3.50496 7.19 3.50496Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <FormattedMessage id="YourtotalbalanceWithdraw" defaultMessage="Вывести" />
              </Button>
            </div>
          ) : (
            <Button blue disabled={!currencyBalance} styleName="button__invoice" onClick={handleInvoice}>
              <FormattedMessage id="RequestPayment" defaultMessage="Запросить" />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CSSModules(BalanceForm, styles, { allowMultiple: true })
