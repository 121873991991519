import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { FormattedMessage, IntlContext } from 'react-intl'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { FaTwitter, FaFacebook, FaGithub, FaReddit } from 'react-icons/fa'
import DOMPurify from 'dompurify'
import logoAmanat from '../../../images/logo/logo.png'
import styles from './Coin.scss'

function Coin() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { coinId } = useParams()
  const { locale } = useContext(IntlContext)
  const url = `https://api.coingecko.com/api/v3/coins/${coinId}?localization=false&sparkline=true`
  const [coin, setCoin] = useState <any>({})

  const amaPrice = [
    1.000187649842106,
    0.9997025396537346,
    0.9989370821347379,
    0.9993860369056845,
    0.9995012343222066,
    0.9998611214843521,
    0.9980287637528534,
    0.9992239113173722,
    0.9998701409308156,
    1.000935628057725,
    0.9997539747745761,
    0.9995995390922032,
    0.999091696221606,
    0.9999965395947608,
    1.0003366487320808,
    0.9994353978807636,
    0.9998260326826287,
    0.9998017505494324,
    0.9993893991797808,
    0.9996205182884615,
    1.0001828293718098,
    1.000439473706304,
    0.9993339896252922,
    0.9999700906247606,
    1.0000809567488775,
    0.9995635000504557,
    0.9998807497383958,
    0.9997162207433349,
    1.000039376442485,
    1.000147840172435,
    0.999659397859921,
    1.000801144118561,
    0.996555649049582,
    1.001181614531508,
    0.9997704124443487,
    0.9995831822148544,
    1.0000599279835114,
    0.9998967339108588,
    0.9999990155767435,
    1.0001694429802899,
    0.9994605855596702,
    0.9999770810266122,
    0.9996976672827299,
    1.000112111995642,
    1.0005744817204814,
    1.0001326669345703,
    1.0000805208179393,
    1.0000415891744803,
    0.9990339875819483,
    1.0003806730333833,
    0.9999968713108851,
    0.9998729996874597,
    1.000056117201571,
    1.0002883772021014,
    0.9996173631805553,
    1.0003868847105362,
    1.0001363173212094,
    0.9996880085355184,
    0.9990801614158907,
    1.0008100033794662,
    1.0003471750512831,
    1.0001010415271674,
    0.9999011021191262,
    1.0003120212214702,
    0.9997570717830286,
    0.9994583892536902,
    0.9977713025622251,
    0.9995197392242721,
    0.9999389195084899,
    0.9991838649180321,
    0.9993953948030765,
    0.9998573971849497,
    0.9996612880516148,
    1.0000916817306729,
    0.9998591833593399,
    0.9998425585689763,
    1.000640523646376,
    0.9995439403187287,
    1.0002685722594875,
    0.9993280589208582,
    0.9995376645507554,
    1.0003689305155887,
    0.9995892514800583,
    1.000489688324119,
    1.0000888798478516,
    0.9989691994246415,
    0.9999395421468446,
    1.0029301059270372,
    0.9996606438880886,
    1.0000285298298257,
    0.998780268899255,
    1.0004507981137685,
    0.9999101039569316,
    0.9987551723890883,
    0.9990711115821774,
    0.9990797665053776,
    0.9996611688955361,
    0.999573999472256,
    0.9993345259626308,
    0.9998359063955111,
    0.9963942109365351,
    0.9998873493489195,
    0.9993549668523548,
    0.9993963646881083,
    0.9999890661843477,
    0.9982682477229023,
    1.0007489172869055,
    0.9994416274131419,
    1.0005305285228552,
    0.9998302222704938,
    0.9991876720228371,
    0.9999460933565721,
    1.0002969378018998,
    0.9993665305735363,
    0.9986429751440978,
    0.9981993895576412,
    0.9995689898298673,
    0.9996960347767062,
    0.9997519832027691,
    1.0006616228451943,
    0.9997126535113483,
    1.0004655981720774,
    0.9989086929817855,
    0.9998773366182395,
    0.9999467053879738,
    1.000134570864544,
    1.0002765906084077,
    0.997533997490411,
    0.9986098963930793,
    0.9991706868197504,
    0.9997619799845708,
    1.0008050344325468,
    0.999184101846933,
    0.999319432909939,
    1.0002662610857693,
    0.9979812143679258,
    1.0000043697163874,
    0.9995578671726448,
    1.0004522953913484,
    0.9998478467088371,
    0.9999493845912439,
    0.9993954879893419,
    0.9933103722972656,
    0.9974490728754948,
    0.9994326084928592,
    1.000827276423435,
    1.0001109110654964,
    0.9997351665819785,
    0.9993574292108022,
    0.9999400386674369,
    0.9995074675417875,
    1.0010682287885408,
    0.9997133484305786,
    0.9988435916443172,
    0.9990225769813548,
    1.0013228819942888,
    0.999459940254537,
    0.9992993053083288,
    0.9995409628153556,
    0.9986149194023403,
    0.9995384348126731,
    1.0009807979897205,
    0.9991752470757633,
    0.9990760087885757,
    0.9998960834574538,
    0.9999554879407347,
    0.9990180875615942,
    1.000211621476853,
  ]

  useEffect(() => {
    axios.get(url).then((res) => {
      setCoin(res.data)
    })
  }, [url])

  console.log('coin', coin)
  console.log('coinId', coinId)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <div className={styles.coin}>
      <div className={styles.coinHead}>
        <img className={styles.coinHeadImage} src={coinId === 'ama' ? logoAmanat : coin.image?.large} alt={coinId === 'ama' ? 'Amanat Coin' : coin?.name} />
        <div>
          <p className={styles.coinHeadName}>
            {
              locale === 'en' ? `${coinId === 'ama' ? 'Amanat Coin' : coin?.name} Price` : `Цена ${coinId === 'ama' ? 'Amanat Coin' : coin?.name}`
            }
          </p>
          <p className={styles.coinHeadSymbol}>
            {
              coinId === 'ama' ? '(AMA / USD)' : `(${coin?.symbol} / USD)`
            }
          </p>
        </div>
      </div>
      <div className={styles.coinCenter}>
        <div>
          <div className={styles.coinCenterTop}>
            {
              coinId === 'ama' ? (
                <p className={styles.coinCenterTopPrice}>
                  $0.013
                </p>
              ) : (coin?.market_data?.current_price ? (
                <p className={styles.coinCenterTopPrice}>
                  $
                  {coin.market_data.current_price.usd.toLocaleString()}
                </p>
              ) : null)
            }
            <p>
              <FormattedMessage
                id="7Day"
                defaultMessage="7 Day"
              />
            </p>
          </div>
          <div>
            {
              coinId === 'ama' ?             (
                <Sparklines data={amaPrice}>
                  <SparklinesLine color="teal" />
                </Sparklines>
              ) :             (
                <Sparklines data={coin.market_data?.sparkline_7d.price}>
                  <SparklinesLine color="teal" />
                </Sparklines>
              )
            }
          </div>
          <div className={styles.coinCenterBottom}>
            <div>
              <p className={styles.coinCenterBottomText}>
                <FormattedMessage
                  id="MarketCap"
                  defaultMessage="Market Cap"
                />
              </p>
              {
                coinId === 'ama' ?                 (
                  <p>
                    $13 000 000
                  </p>
                ) : coin.market_data?.market_cap ? (
                  <p>
                    $
                    {coin.market_data.market_cap.usd.toLocaleString()}
                  </p>
                ) : null
              }
            </div>
            <div>
              <p className={styles.coinCenterBottomText}>
                <FormattedMessage
                  id="Volume24h"
                  defaultMessage="Volume (24h)"
                />
              </p>
              {
                coinId === 'ama' ?                 (
                  <p>
                    $1 190 000
                  </p>
                ) : coin.market_data?.market_cap ? (
                  <p>
                    $
                    {coin.market_data.total_volume.usd.toLocaleString()}
                  </p>
                ) : null
              }
            </div>
          </div>
          <div className={styles.coinCenterBottom}>
            <div>
              {
                coinId !== 'ama' &&               (
                  <p className={styles.coinCenterBottomText}>
                    <FormattedMessage
                      id="24hHigh"
                      defaultMessage="24h High"
                    />
                  </p>
                )
              }
              {coin.market_data?.high_24h ? (
                <p>
                  $
                  {coin.market_data.high_24h.usd.toLocaleString()}
                </p>
              ) : null}
            </div>
            <div>
              {
                coinId !== 'ama' &&               (
                  <p className={styles.coinCenterBottomText}>
                    <FormattedMessage
                      id="24hLow"
                      defaultMessage="24h Low"
                    />
                  </p>
                )
              }
              {coin.market_data?.low_24h ? (
                <p>
                  $
                  {coin.market_data.low_24h.usd.toLocaleString()}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <p className={styles.coinCenterSecondTitle}>
            <FormattedMessage
              id="MarketStats"
              defaultMessage="Market Stats"
            />
          </p>
          <div className={styles.coinCenterSecondItem} style={coinId === 'ama' ? { justifyContent: 'flex-start', gap: '24px' } : {}}>
            <div>
              <p className={styles.coinCenterBottomText}>
                <FormattedMessage
                  id="MarketRank"
                  defaultMessage="Market Rank"
                />
              </p>
              {coinId === 'ama' ? <p>1</p> : coin.market_cap_rank ? <p>{coin.market_cap_rank}</p> : null}
            </div>
            {
              coinId !== 'ama' &&             (
                <div>
                  <p className={styles.coinCenterBottomText}>
                    <FormattedMessage
                      id="HashingAlgorithms"
                      defaultMessage="Hashing Algorithms"
                    />
                  </p>
                  {coin.hashing_algorithm ? <p>{coin.hashing_algorithm}</p> : null}
                </div>
              )
            }
            <div>
              <p className={styles.coinCenterBottomText}>
                <FormattedMessage
                  id="TrustScore"
                  defaultMessage="Trust Score"
                />
              </p>
              {coinId === 'ama' ? <p>99.9%</p> : coin.tickers ? <p>{coin.liquidity_score.toFixed(2)}</p> : null}
            </div>
          </div>
          {
            coinId !== 'ama' && (
              <>
                <div className={styles.coinCenterSecondItem}>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange24h"
                        defaultMessage="Price Change (24h)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_24h.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange7d"
                        defaultMessage="Price Change (7d)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_7d.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange14d"
                        defaultMessage="Price Change (14d)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_14d.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className={styles.coinCenterSecondItem}>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange30d"
                        defaultMessage="Price Change (30d)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_30d.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange60d"
                        defaultMessage="Price Change (60d)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_60d.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <p className={styles.coinCenterBottomText}>
                      <FormattedMessage
                        id="PriceChange1y"
                        defaultMessage="Price Change (1y)"
                      />
                    </p>
                    {coin.market_data ? (
                      <p>
                        {coin.market_data.price_change_percentage_1y.toFixed(2)}
                        %
                      </p>
                    ) : null}
                  </div>
                </div>
              </>
            )
          }
          {
            coinId !== 'ama' &&           (
              <div className={styles.coinCenterSecondSocial}>
                <a href={`https://twitter.com/${coin?.links?.twitter_screen_name}`} target="_blank" rel="noreferrer">
                  <FaTwitter size={20} />
                </a>
                {
                  coin?.links?.facebook_username !== '' && coin?.links?.facebook_username && (
                    <a href={`https://www.facebook.com/${coin?.links?.facebook_username}`} target="_blank" rel="noreferrer">
                      <FaFacebook size={20} />
                    </a>
                  )
                }
                <a href={coin?.links?.subreddit_url} target="_blank" rel="noreferrer">
                  <FaReddit size={20} />
                </a>
                <a href={coin?.links?.repos_url?.github[0]} target="_blank" rel="noreferrer">
                  <FaGithub size={20} />
                </a>
              </div>
            )
          }
        </div>
      </div>
      {/* Description */}
      <div className={styles.coinFooter}>
        <p className={styles.coinCenterSecondTitle}>
          <FormattedMessage
            id="AboutCoinMarket"
            defaultMessage="About"
          />
          &nbsp;
          {coinId === 'ama' ? 'Amanat Coin' : coin.name}
        </p>
        {/* npm i dompurify */}
        {
          coinId === 'ama' ? (
            <p className={styles.coinFooterText}>
              <FormattedMessage
                id="AboutCoinMarketText"
                /* eslint-disable-next-line max-len */
                defaultMessage="ATC is the stabilizing governance token of the AMADAO NETWORK ecosystem, an automated multi-chain token of the DEX markets. AMANAT COIN allows token holders to participate in the governance of the ecosystem. Key decisions, such as the use of the treasury or future upgrades, can be made through management votes."
              />
            </p>
          ) : <p className={styles.coinFooterText} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(coin.description ? coin.description.en : '') }} />
        }
      </div>
    </div>
  )
}

export default Coin
