import styles from './AccordionHeader.scss'
/* eslint linebreak-style: ["error", "windows"] */

function AccordionHeader({ title, onClick, isActive }) {
  return (
    <div onClick={onClick} className={styles.accordionHeader}>
      <p className={styles.accordionHeaderTitle}>{title}</p>
      <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" className={isActive ? `${styles.accordionHeaderIcon} ${styles.accordionHeaderIconActive}` : styles.accordionHeaderIcon}>
        <path d="M8 10L12 14L16 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </div>
  );
}

export default AccordionHeader